<template>
  <!--悬浮窗口样式的提示信息-->
  <!--  @mouseover：当鼠标移入是触发，移入和移出其子元素时候也会触发
        @mouseout：当鼠标移出元素时候触发，移入和移出其子元素也会触发
      -->
  <div v-if="false">
    <div
      id="theDiv"
      ref="theDiv"
      @mouseover="clearFdAd"
      style="position: absolute"
      @mouseout="starFdAd"
      v-show="theDivShow"
    >
      <div>
        <div
          style="
            position: absolute;
            z-index: 9999;
            cursor: pointer;
            right: 10px;
            top: 5px;
            font-size: 12px;
          "
          @click="theDivShow = false"
        >
          <span style="color: #e5cd93; z-index: 9999">关闭</span>
        </div>
        <!--  图片的长宽  -->
        <div
          @click="handleOpen"
          :style="{
            width: `${width}px`,
            height: `${height}px`,
          }"
        >
          <a :href="item.href" target="_blank">
            <img :src="item.image" class="img1" style="border: 0" alt="" />
          </a>
        </div>
      </div>
    </div>

    <div class="proposal" v-show="showOpen">
      <div class="close" @click="handleClose">关闭</div>
      <!-- src="../assets/images/cysnr3.png" -->
      <img
        ref="imgRef"
        v-viewer
        src="../assets/images/floatWindowWithin.jpg"
        alt=""
        class="image-photo"
      />
    </div>
  </div>
</template>
<script>
import img1 from "../assets/images/cysnr3.png";
export default {
  name: "FloatingWindow",
  props: {
    item: {
      type: Object,
      default() {
        return {
          image: img1,
        };
      },
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      timer: null,
      showOpen: false,
      // 最顶层左右移动，数值越大越靠右
      xPos: 0,
      // 靠左上下移动，数值越大起始位置越靠下
      yPos: 0,
      // 移动速度，数值越大移动速度越快
      step: 1,
      // 周期性执行或者调用code之间的时间间隔
      delay: 15,
      // height: 0,
      Hoffset: 0,
      Woffset: 0,
      // 与yPos结合使用，0代表向上移动，1代表向下移动
      yon: 0,
      // 与xPos结合使用，0代表向右下方移动，1代表向左下方移动
      xon: 0,
      pause: true,
      // 开启窗口
      theDivShow: true,
      start: "",
    };
  },
  watch: {
    theDivShow() {
      console.log(this.theDivShow);
    },
  },
  mounted() {
    // 第一个飘动窗口
    // setInterval: 会不停的调用函数，直到clearInterval被调用或者窗口被关闭。由setInterval返回的ID值可用做clearInterval方法的参数
    // setInterval（code，millisec[,"lang"]）
    // code: 必需的。要调用的函数或者要执行的代码串
    // millisec：必需的.周期性执行或者调用code之间的时间间隔，以毫秒计算
    this.start = setInterval(this.changePos, this.delay);
  },
  created() {
    
    this.xPos = Math.round(Math.random() * 1670); // 生成0~1670的随机整数
    this.yPos = Math.round(Math.random() * 868); // 生成0~868的随机整数
    this.yon = Math.round(Math.random()); // 生成0~1的随机整数
    this.xon = Math.round(Math.random()); // 生成0~1的随机整数
  },
beforeDestroy () {
      if (this.start) {
        clearInterval(this.start)
        this.start = ''
      }
    },
  methods: {
    hide() {
      // 打开原图
      this.showOpen = true;
    },
    show() {
      // 关闭原图
      this.showOpen = false;
    },
    handleOpen() {
      if (this.$refs.imgRef) {
        this.$refs.imgRef.$viewer.show();
      }
    },
    handleClose() {
      this.showOpen = false;
    },
    // 飘动窗口配置
    changePos() {
      // 网页可见区域的宽度
      let width = document.documentElement.clientWidth;
      // 网页可见区域的高度
      let height = document.documentElement.clientHeight;
      // 获取标签元素的高度
      this.Hoffset = this.$refs.theDiv.offsetHeight;
      // 获取标签元素的宽度
      this.Woffset = this.$refs.theDiv.offsetWidth;

      // 滚动部分跟随屏幕滚动
      // document.body.scrollLeft 网页被卷去的左
      // scrollLeft：设置或获取位于对象左边界和窗口中目前可见内容的最左端之间的距离
      // document.documentElement.scrollLeft ： 设置或获取页面文档向右滚动过的像素数
      this.$refs.theDiv.style.left =
        this.xPos +
        document.body.scrollLeft +
        document.documentElement.scrollLeft +
        "px";
      // document.body.scrollTop 网页被卷去的高
      // scrollTop：设置或获取位于对象对顶端和窗口中可见内容的最顶端之间的距离
      // document.documentElement.scrollTop ：设置或获取页面文档向下滚动过的像素数
      this.$refs.theDiv.style.top =
        this.yPos +
        document.body.scrollTop +
        document.documentElement.scrollTop +
        "px";

      // 滚动部分不随屏幕滚动
      // this.$refs.theDiv.style.left = this.xPos + document.body.scrollLeft + 'px'
      // this.$refs.theDiv.style.top = this.yPos + document.body.scrollTop + 'px'

      if (this.yon) {
        this.yPos = this.yPos + this.step;
      } else {
        this.yPos = this.yPos - this.step;
      }
      if (this.yPos < 0) {
        this.yon = 1;
        this.yPos = 0;
      }
      if (this.yPos >= height - this.Hoffset) {
        this.yon = 0;
        this.yPos = height - this.Hoffset;
      }

      if (this.xon) {
        this.xPos = this.xPos + this.step;
      } else {
        this.xPos = this.xPos - this.step;
      }
      if (this.xPos < 0) {
        this.xon = 1;
        this.xPos = 0;
      }
      if (this.xPos >= width - this.Woffset) {
        this.xon = 0;
        this.xPos = width - this.Woffset;
      }
    },
    // 鼠标接触停止配置
    clearFdAd() {
      // clearInterval：可以取消由setInterval设置的timeout时间间隔。参数必需是setInterval返回的ID值-->
      clearInterval(this.start);
    },
    starFdAd() {
      this.start = setInterval(this.changePos, this.delay);
    },
  },
};
</script>
 
<style lang="scss" scoped>
#theDiv {
  z-index: 100;
  position: absolute;
  // background: red;
  .img1 {
    width: 100%;
    height: 100%;
  }
}
.proposal {
  position: fixed;
  width: 800px;
  height: 200px;
  top: 20%;
  left: 50%;
  transform: translateX(-50%); // 水平居中
  z-index: 1000;
  .image-photo {
    width: 100%;
    object-fit: cover;
  }
  .close {
    position: absolute;
    right: 0px;
    top: 0px;
    font-size: 12px;
    color: #e5cd93;
    cursor: pointer;
  }
}
@media (min-width: 720px) {
  .proposal {
    top: 3%;
    width: 700px;
    height: 600px;
  }
}
@media (max-width: 700px) {
  .proposal {
    top: 3%;
    width: 660px;
    height: 600px;
  }
}
@media (min-width: 600px) {
  .proposal {
    top: 2%;
    width: 500px;
    height: 500px;
  }
}
@media (min-width: 1918px) {
  .proposal {
    top: 1%;
    width: 680px;
    height: 500px;
  }
}
@media (min-width: 360px) and (max-width: 480px) {
  .proposal {
    top: 3%;
    width: 400px;
    height: 400px;
  }
}
</style>